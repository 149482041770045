@import '@postgres.ai/shared/styles/vars';

.interactiveRow {
  cursor: pointer;
}

.verticalCentered {
  display: flex;
  align-items: center;
}

.infoIcon {
  margin-left: 4px;
  height: 12px;
  width: 12px;
}

.sortIcon {
  margin-left: 8px;
  width: 10px;
}

.protectionIcon {
  display: block;
  margin-left: 5px;
  height: 1em;
  color: $color-gray-semi-dark;
}

.emptyStub {
  margin-top: 16px;
}
